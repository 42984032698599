























import Vue from 'vue'
import { BAlert, BLink } from 'bootstrap-vue'
import { onUnmounted } from '@vue/composition-api'
import { getAppConfigStore } from '@/store'
import Ecommerce from '@/views/dashboard/ecommerce/Ecommerce.vue'

export default Vue.extend({
  components: {
    BAlert,
    BLink,
    Ecommerce,
  },
  setup() {
    const appConfigStore = getAppConfigStore()
    const { contentWidth } = appConfigStore.layout

    appConfigStore.UPDATE_CONTENT_WIDTH('boxed')

    onUnmounted(() => {
      appConfigStore.UPDATE_CONTENT_WIDTH(contentWidth as any)
    })
  },
})
